import { useEffect } from 'react';
import { navigate } from 'gatsby';

const FB = () => {
  useEffect(() => {
    navigate('/about/');
  }, []);
  
  return null
};

export default FB